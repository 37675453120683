<template>
  <div class="ui-icon-picker">
    <ui-icon
      class="icon-picker-current ui-clickable"
      :value="value"
      :color="color"
      @click="isDialogOpen = true"
    ></ui-icon>

    <ui-dialog
      :open.sync="isDialogOpen"
      class="ui-icon-picker"
      @close="onDialogClose"
    >
      <template #header>
        <slot name="header"></slot>

        <input
          class="icon-picker-search"
          type="text"
          @input="setSearchString($event.target.value)"
          placeholder="Buscar ..."
        />

        <div
          class="page-list ui-noselect"
          v-show="!searchString"
        >
          <div
            class="page-item ui-clickable --prev"
            @click="currentPage = Math.max(currentPage-1, 1)"
          >&lsaquo;</div>

          <div
            class="page-item ui-clickable"
            :class="{'--selected': currentPage == n}"
            v-for="n in nPages"
            :key="n"
            @click="currentPage = n"
          >{{ n }}</div>

          <div
            class="page-item ui-clickable --next"
            @click="currentPage = Math.min(currentPage+1, nPages)"
          >&rsaquo;</div>
        </div>
      </template>

      <div class="icon-grid">
        <span
          v-for="iconName in listedIcons"
          :key="iconName"
          :class="['mdi',  `mdi-${iconName}`, 'ui-clickable', {'--selected': value == `mdi:${iconName}`}]"
          :title="iconName"
          @click="$emit('input', `mdi:${iconName}`); isDialogOpen = false;"
        ></span>
      </div>
    </ui-dialog>
  </div>
</template>

<script>
import mdiIcons from './Mdi.js';
import { UiIcon, UiDialog } from '@/modules/ui/components';

export default {
  name: 'ui-icon-picker',
  components: { UiIcon, UiDialog },

  props: {
    value: {
      type: String,
      required: false,
      default: null
    },

    color: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      isDialogOpen: false,
      searchString: '',
      searchTimer: null,
      pageSize: 250,
      currentPage: 1
    };
  },

  computed: {
    nPages() {
      return Math.ceil(mdiIcons.length / this.pageSize);
    },

    listedIcons() {
      let query = this.searchString.trim();
      if (query) {
        let exp = new RegExp(query);
        return mdiIcons.filter(i => exp.test(i));
      }

      let start = (this.currentPage - 1) * this.pageSize;
      let end = start + this.pageSize;

      return mdiIcons.slice(start, end);
    }
  },

  methods: {
    onDialogClose() {
      this.searchString = '';
      this.currentPage = 1;
    },

    setSearchString(value) {
      let trimmedValue = value.trim();
      if (!trimmedValue) {
        this.searchString = '';
        return;
      }

      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(
        () => (this.searchString = trimmedValue),
        400
      );
    }
  }
};
</script>

<style lang="scss">
.ui-icon-picker {
  .icon-picker-current {
    padding: var(--ui-padding);
    --ui-icon-size: 28px;
  }

  .icon-picker-search {
    display: block;
    width: 100%;
    font: inherit;
    border: 0;
    background-color: rgba(0, 0, 0, 0.03);
    padding: var(--ui-padding);
  }

  .page-list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .page-item {
      display: block;
      padding: 8px 12px;
      border-radius: var(--ui-radius);

      &.--selected {
        font-weight: bold;
        color: var(--ui-color-primary);
      }
    }
  }

  .icon-grid {
    display: flex;
    flex-wrap: wrap;

    .mdi {
      display: block;
      padding: 8px 12px;
      font-size: 28px;
      border: 1px solid transparent;
      color: #666;

      &.--selected {
        color: var(--ui-color-primary);
        border: 1px solid var(--ui-color-primary);
        border-radius: var(--ui-radius);
      }
    }
  }
}
</style>